@import '../../../shared/style/sass/color-vars.scss';
@import '../../../shared/style/sass/size-vars.scss';

.auth {
  &__wrapper {
    padding: 0.5rem 1.125rem 1rem;
  }

  &__paper {
    min-width: unset;
    width: 18rem;
    margin: 0 auto;
    padding: 2rem 1.125rem;
    box-sizing: border-box;
  }

  &__buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
  }

  @media all and (min-width: 22rem) {
    &__paper {
      width: 20rem;
      padding: 2rem;
    }
  }
}
